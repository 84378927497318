const breakpoints = {
  xx: 0,
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1340,
  xl: 1600,
}

export default breakpoints
