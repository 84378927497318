/* Internet Explorer 11 Support */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // make sure you add this for firestore
import { USER_PROFILE } from './Config'
// eslint-disable-next-line import/no-unresolved
import FIREBASE_CONFIG from './Config/firebase.json'
import AccountActions from './Redux/AccountRedux'
import './global.css'
import App from './App'
import AppActions from './Redux/AppRedux'
import createStore from './Redux/CreateStore'
import * as serviceWorker from './serviceWorker'

const NextApp = require('./App').default
// Create a store and get back itself and its history object
const { store, history } = createStore()

const onUpdate = registration => {
  // Save Service Worker as Global Variable.
  window.myServiceWorker = registration
  store.dispatch(AppActions.AppSetUpdateAvailable())
}
const onSuccess = () => {
  store.dispatch(AppActions.AppSetCached())
}

serviceWorker.register({ onUpdate, onSuccess })

const rootEl = document.getElementById('root')

// initialize firebase instance once
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG)
}

const rrfProps = {
  firebase,
  config: {
    userProfile: USER_PROFILE,
    useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
    onAuthStateChanged: async (auth, firebase, dispatch) => {
      if (!auth) {
        dispatch(AccountActions.AccountSetClaims(''))
        return
      }
      const user = firebase.auth().currentUser
      const idTokenResult = await user.getIdTokenResult()
      dispatch(AccountActions.AccountSetClaims(idTokenResult.claims))
    },
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const Application = Component => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Component />
      </ReactReduxFirebaseProvider>
    </ConnectedRouter>
  </Provider>
)

render(Application(App), rootEl)

if (module.hot) {
  module.hot.accept('./App', () => {
    render(Application(NextApp), rootEl)
  })
}
