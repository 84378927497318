import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'
import loadable from '@loadable/component'
import Routes from '../enums/routes'
import GoogleTagManager from '../Components/GoogleTagManager'
import { GOOGLE_ANALYTICS, GOOGLE_TAG_MANAGER } from '../Config'

/** Import Each Page Component On-Demand * */
const AsyncLoginCallback = loadable(() => import('../Containers/LoginCallback'))
const AsyncDashboard = loadable(() => import('../Containers/Dashboard'))
const AsyncCollections = loadable(() => import('../Containers/Collections'))
const AsyncTriggerPanel = loadable(() => import('../Containers/TriggerPanel'))
const AsyncLearnMode = loadable(() => import('../Containers/LearnMode'))
const AsyncRemote = loadable(() => import('../Containers/Remote'))
const AsyncLeftDisplayPage = loadable(() => import('../Containers/LeftDisplayPage'))
const AsyncCenterDisplayPage = loadable(() => import('../Containers/CenterDisplayPage'))
const AsyncRightDisplayPage = loadable(() => import('../Containers/RightDisplayPage'))
const AsyncDisplayPage = loadable(() => import('../Containers/DisplayPage'))
const AsyncAuxDisplayPage = loadable(() => import('../Containers/AuxDisplayPage'))
const AsyncSalesFirebase = loadable(() => import('../Containers/SalesFirebase'))
const AsyncSalesPublic = loadable(() => import('../Containers/SalesPublic'))
const AsyncError = loadable(() => import('../Containers/Error'))
const AsyncTouch = loadable(() => import('../Containers/Touch'))
const AsyncTouchSimulation = loadable(() => import('../Containers/TouchSimulation'))
const AsyncSimulationDetailPage = loadable(() => import('../Containers/SimulationDetailPage'))
const AsyncAssemble = loadable(() => import('../Containers/Assemble'))
const AsyncEditSimulation = loadable(() => import('../Containers/EditSimulation'))
const AsyncPreviewSimulation = loadable(() => import('../Containers/PreviewSimulation'))
const AsyncDetails = loadable(() => import('../Containers/Details'))
const AsyncMXHomePage = loadable(() => import('../Containers/MXHomePage'))
const AsyncMXCollectionPage = loadable(() => import('../Containers/MXCollectionPage'))
const AsyncMXLaunchPage = loadable(() => import('../Containers/MXLaunchPage'))

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    if (GOOGLE_ANALYTICS) {
      ReactGA.initialize(GOOGLE_ANALYTICS)
      this.sendPageView(this.context.router.history.location)
      this.context.router.history.listen(this.sendPageView)
    }
  }

  sendPageView(location) {
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    }
  }

  render() {
    return this.props.children
  }
}

const Navigation = () => (
  <GAListener>
    <GoogleTagManager gtmId={GOOGLE_TAG_MANAGER} />
    <Switch>
      <Route exact path={Routes.DASHBOARD} component={AsyncDashboard} />
      <Route exact path={Routes.VERTICALS} component={AsyncCollections} />
      <Route exact path={Routes.EVENTS} component={AsyncCollections} />
      <Route exact path={Routes.CALLBACK} component={AsyncLoginCallback} />
      <Route exact path={Routes.MARKETING_EXPERIENCE} component={AsyncMXHomePage} />
      <Route exact path={Routes.MARKETING_EXPERIENCE_COLLECTION_ITEM} component={AsyncMXCollectionPage} />
      <Route exact path={Routes.MARKETING_EXPERIENCE_SIM_ITEM} component={AsyncMXLaunchPage} />
      <Route exact path={Routes.INNOVATION_CENTER} component={AsyncTriggerPanel} />
      <Route exact path={Routes.INNOVATION_CENTER_LEARN_MODE} component={AsyncLearnMode} />
      <Route exact path={Routes.INNOVATION_REMOTE} component={AsyncRemote} />
      <Route exact path={Routes.INNOVATION_LEFT_DISPLAY} component={AsyncLeftDisplayPage} />
      <Route exact path={Routes.INNOVATION_CENTER_DISPLAY} component={AsyncCenterDisplayPage} />
      <Route exact path={Routes.INNOVATION_RIGHT_DISPLAY} component={AsyncRightDisplayPage} />
      <Route exact path={Routes.INNOVATION_CHOOSE_DISPLAY} component={AsyncDisplayPage} />
      <Route exact path={Routes.INNOVATION_AUX_DISPLAY} component={AsyncAuxDisplayPage} />
      <Route exact path={Routes.SIMULATION_DETAILS} component={AsyncSimulationDetailPage} />
      <Route exact path={Routes.SALES_ENABLEMENT_PUBLIC_MX} component={AsyncSalesPublic} />
      <Route exact path={Routes.SALES_ENABLEMENT_PUBLIC} component={AsyncSalesPublic} />
      <Route exact path={Routes.SALES_ENABLEMENT_FIREBASE} component={AsyncSalesFirebase} />
      <Route exact path={Routes.TOUCH} component={AsyncTouch} />
      <Route exact path={Routes.TOUCH_SIMULATION} component={AsyncTouchSimulation} />
      <Route exact path={Routes.ASSEMBLE} component={AsyncAssemble} />
      <Route exact path={Routes.EDIT_SIMULATION} component={AsyncEditSimulation} />
      <Route exact path={Routes.PREVIEW_SIMULATION} component={AsyncPreviewSimulation} />
      <Route exact path={Routes.IC_DETAILS} component={AsyncDetails} />
      <Route path={Routes.ERROR} component={AsyncError} />
    </Switch>
  </GAListener>
)

export default Navigation
