import palette from './palette'
import breakpoints from './breakpoints'
import { fontWeight } from './typography'

const depth = {
  100: '0 0 2px 0 rgba(0, 0, 0, .12)',
  200: '0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .12), 0 1px 3px 0 rgba(0, 0, 0, .14)',
  300: '0 2px 4px 0 rgba(0, 0, 0, .10), 0 3px 4px 0 rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .14)',
  400: '0 3px 3px 0 rgba(0, 0, 0, .10), 0 3px 4px 0 rgba(0, 0, 0, .12), 0 1px 8px 0 rgba(0, 0, 0, .14)',
  500: '0 2px 4px 0 rgba(0, 0, 0, .10), 0 4px 5px 0 rgba(0, 0, 0, .12), 0 1px 10px 0 rgba(0, 0, 0, .14)',
  600: '0 6px 10px 0 rgba(0, 0, 0, .10), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px 0 rgba(0, 0, 0, .14)',
  700: '0 8px 10px 1px rgba(0, 0, 0, .10), 0 3px 14px 3px rgba(0, 0, 0, .12), 0 4px 5px 0 rgba(0, 0, 0, .14)',
  800: '0 12px 17px 2px rgba(0, 0, 0, .10), 0 5px 22px 4px rgba(0, 0, 0, .12), 0 7px 8px 0 rgba(0, 0, 0, .14)',
  900: '0 16px 24px 2px rgba(0, 0, 0, .10), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px 0 rgba(0, 0, 0, .14)',
}

const globals = {
  transitionSpeed: '.2s',
  transitionSpeedFast: '.1s',
  transitionSpeedSlow: '.4s',
  transition: 'all .2s ease-in-out',
  transitionFast: 'all .1s ease-in-out',
  transitionSlow: 'all .4s ease-in-out',
  cubicBezier: 'cubic-bezier(.71, .31, .52, .95)',
  border: `1px solid ${palette.border.main}`,
  borderDarkish: `1px solid ${palette.border.divider}`,
  borderDark: `1px solid ${palette.border.dark}`,
  borderDarker: `1px solid ${palette.border.darker}`,
  borderLight: `1px solid ${palette.border.light}`,
  borderPrimary: `1px solid ${palette.border.primary}`,
  borderDisabled: `1px solid ${palette.border.disabled}`,
  divider: `1px solid ${palette.border.divider}`,
  borderTransparent: `1px solid transparent`,
  borderRadiusLarge: 10,
  borderRadius: 6,
  borderRadiusSmall: 4,
  depth,
  boxShadow: '0 2px 24px 0 rgba(0, 0, 0, .32)',
  boxShadowSmallLight: '0 2px 9px 0 rgba(0, 0, 0, .16)',
  boxShadowSmallDark: '0 2px 14px 0 rgba(0, 0, 0, .32)',
  boxShadowSmallDarker: '0 2px 8px 0 rgba(0, 0, 0, .5)',
  boxShadowSmall: '0 2px 14px 0 rgba(0, 0, 0, .32)',
  boxShadowSmaller: '0 2px 9px 0 rgba(0, 0, 0, .32)',
  boxShadowSmallerYShift: '0 7px 9px 0 rgba(0, 0, 0, .32)',
  headerHeight: 50,
  containerWidth: breakpoints.lg,
  aspectRatio: {
    display: '176%',
    displayWide: '86.9%',
    sequence: '56.5%',
  },
  fontWeight,
}

const root = document.documentElement

const screenSize = () => {
  root.style.setProperty('--screen-width', `${window.innerWidth}px`)
  root.style.setProperty('--screen-height', `${window.innerHeight}px`)
}

window.addEventListener('load', screenSize)
window.addEventListener('resize', screenSize)

export default globals
