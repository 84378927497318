import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const UpdateSnackbar = () => {
  const updateAvailable = useSelector(({ app }) => app.updateAvailable)

  const handleRefresh = () => {
    // Grab Service Worker from Global Scope
    if (window.myServiceWorker && window.myServiceWorker.waiting) {
      // Tell Service Worker not to wait for all tabs to close.
      window.myServiceWorker.waiting.postMessage({ action: 'skipWaiting' })
    }
    // Reload page.
    window.location.reload(true)
  }

  useEffect(() => {
    if (updateAvailable) handleRefresh()
  }, [updateAvailable])

  return null
}

export default UpdateSnackbar
