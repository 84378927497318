/* eslint-disable import/no-unresolved */
import config from './config.json'
import fb from './firebase.json'

let SUFFIX = ''
export const ENVIRONMENT = config.environment
export const COMPANY = config.id
export const WEBSITE_CONFIG_ID = config.id
export const WEBSITE_VERSION = config.version

if (ENVIRONMENT === 'qa') {
  SUFFIX = '_qa'
} else if (ENVIRONMENT === 'stage' || ENVIRONMENT === 'staging') {
  SUFFIX = '_stage'
} else if (ENVIRONMENT === 'dev') {
  SUFFIX = '_dev'
}

export const USE_LOCAL = false

export const OIDC_CONFIG_LOCAL = {
  issuer: 'http://localhost:4000/api',
  authorization_endpoint: 'http://localhost:4000/api/auth',
  token_endpoint: 'http://localhost:4000/api/token',
  userinfo_endpoint: 'http://localhost:4000/api/me',
  jwks_uri: 'http://localhost:4000/api/certs',
}

const OIDC_CONFIG_PRODUCTION = {
  issuer: 'https://idgateway-ideacloud.forgedx.com/api',
  authorization_endpoint: 'https://idgateway-ideacloud.forgedx.com/auth',
  token_endpoint: 'https://idgateway-ideacloud.forgedx.com/api/token',
  userinfo_endpoint: 'https://idgateway-ideacloud.forgedx.com/api/me',
  jwks_uri: 'https://idgateway-ideacloud.forgedx.com/api/certs',
}

export const OIDC_CONFIG = USE_LOCAL ? OIDC_CONFIG_LOCAL : OIDC_CONFIG_PRODUCTION
export const CONFIG_SSO = config.sso
export const OIDC_CLIENT_ID = 'oidcCLIENT'
export const SALES_URL_PARAM = 'bic'
export const INNOVATION_CENTER_URL_PARAM = 'innovation'
export const DIGITAL_SIGN_PROPERTY = 'interactiveDisplay'
export const PRESENTATION_SIGN_PROPERTY = 'triggerDisplay'
export const LEFT_HORIZONTAL_PROPERTY = 'leftHorizontal'
export const CENTER_CAST_PROPERTY = 'centerCast'
export const AUTORUN_PROPERTY = 'autoRun'
export const RIGHT_HORIZONTAL_PROPERTY = 'rightHorizontal'
export const LEFT_SIDE_PROPERTY = 'leftSide'
export const RIGHT_SIDE_PROPERTY = 'rightSide'
export const LEFT_SIDE_HORIZONTAL_PROPERTY = 'leftSideHorizontal'
export const RIGHT_SIDE_HORIZONTAL_PROPERTY = 'rightSideHorizontal'
export const LEFT_DISPLAY_PROPERTY = 'leftDisplay'
export const CENTER_DISPLAY_PROPERTY = 'centerDisplay'
export const RIGHT_DISPLAY_PROPERTY = 'rightDisplay'
export const AUX_SIGN_PROPERTY = 'auxDisplay'
export const MOBILE_TOGGLE_PROPERTY = 'mobileToggle'
export const BACKGROUND_SIZE_PROPERTY = 'backgroundSize'
export const FAKE_PHONE_KEYBOARD = 'keyboard'
export const IPHONE_X_DIMENSIONS = {
  height: 667,
  width: 375,
  ratio: 0.5622,
}
export const IPHONE_S_DIMENSIONS = {
  height: 812,
  width: 375,
  ratio: 0.4618,
  outerRatio: 0.497,
}

let issuer = null

issuer = USE_LOCAL
  ? OIDC_CONFIG_LOCAL.issuer
  : config.sso &&
    config.sso.environments &&
    config.sso.environments[ENVIRONMENT] &&
    config.sso.environments[ENVIRONMENT].issuer
  ? config.sso.environments[ENVIRONMENT].issuer
  : OIDC_CONFIG_PRODUCTION.issuer

// eslint-disable-next-line no-console
console.log(`ID Gateway Issuer: ${issuer}`)
export const ID_GATEWAY_ISSUER = issuer
export const API_BASE_URL = USE_LOCAL
  ? config.sso
    ? `http://localhost:5000/ideacloud-${WEBSITE_CONFIG_ID}/us-central1/app`
    : 'http://localhost:5000/forgedx-ideacloud-v2/us-central1/app'
  : fb.functionsURL

export const PRODUCTION = ENVIRONMENT === 'production'
const ASSEMBLE_API_BASE_URL_DEV = `https://assemble-api-dev.ideacloud.com`
const ASSEMBLE_API_BASE_URL_PROD = `https://ideacloud-assemble-api.forgedx.com`
export const ASSEMBLE_API_BASE_URL =
  PRODUCTION || ENVIRONMENT === 'stage' ? ASSEMBLE_API_BASE_URL_PROD : ASSEMBLE_API_BASE_URL_DEV
export const USER_PROFILE = 'users'
export const TOKENS_COLLECTION = 'tokens'
export const WEBSITE_CONFIG_COLLECTION = `config${SUFFIX}`
export const APPS_COLLECTION = `apps${SUFFIX}`
export const COLLECTIONS_COLLECTION = `collections${SUFFIX}`
export const SIMULATIONS_COLLECTION = `simulations${SUFFIX}`
export const GOOGLE_ANALYTICS = config.googleAnalytics // eslint-disable-line prefer-destructuring
// Search for config.googleTagManager[ENVIRONMENT], fallback to config.googleTagManager
export const GOOGLE_TAG_MANAGER =
  (config.googleTagManager && config.googleTagManager[ENVIRONMENT]) || config.googleTagManager
// Support for legacy configs
export const GOOGLE_TAG_MANAGER_AUTH = config.googleTagManagerAuth // eslint-disable-line prefer-destructuring
export const GOOGLE_TAG_MANAGER_PREVIEW = config.googleTagManagerPreview // eslint-disable-line prefer-destructuring

export const SUPPORT = 'https://www.ideacloud.com/product-faq'

export const LETTERS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]
