import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { connectRouter } from 'connected-react-router'

const account = require('./AccountRedux').reducer
const app = require('./AppRedux').reducer
const assemble = require('./AssembleRedux').reducer

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    account,
    app,
    assemble,
  })

export default createRootReducer
