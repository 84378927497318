const Routes = {
  DASHBOARD: '/',
  VERTICALS: '/verticals',
  EVENTS: '/events',
  CALLBACK: '/callback',
  MARKETING_EXPERIENCE: '/marketing-experience',
  MARKETING_EXPERIENCE_COLLECTION_ITEM: '/marketing-experience/:collectionId',
  MARKETING_EXPERIENCE_SIM_ITEM: '/marketing-experience/:collectionId/:simId',
  INNOVATION_CENTER: '/:collection/:simulation/experience-controls/:notrigger?',
  INNOVATION_CENTER_LEARN_MODE: '/:collection/:simulation/learn-mode/:notrigger?',
  INNOVATION_LEFT_DISPLAY: '/:collection/:simulation/left-display/:notrigger?',
  INNOVATION_CENTER_DISPLAY: '/:collection/:simulation/center-display/:notrigger?',
  INNOVATION_RIGHT_DISPLAY: '/:collection/:simulation/right-display/:notrigger?',
  INNOVATION_CHOOSE_DISPLAY: '/:collection/:simulation/:display(left|right)/:notrigger?',
  INNOVATION_REMOTE: '/:collection/:simulation/remote/:notrigger?',
  INNOVATION_AUX_DISPLAY: '/:collection/:simulation/aux/:notrigger?',
  SIMULATION_DETAILS: '/:collection/:simulation/simulation-details/:notrigger?',
  SALES_ENABLEMENT_PUBLIC_MX: '/:collection/:simulation/public/mx/:present?',
  SALES_ENABLEMENT_PUBLIC: '/:collection/:simulation/public/:present?',
  SALES_ENABLEMENT_FIREBASE: '/:collection/:simulation/:present/:notrigger?',
  TOUCH: '/:collection/touch',
  TOUCH_SIMULATION: '/:collection/:simulation/TouchSimulation/:notrigger?',
  ASSEMBLE: '/assemble',
  EDIT_SIMULATION: '/edit-simulation/:simId',
  PREVIEW_SIMULATION: '/preview-simulation/:simId',
  IC_DETAILS: '/ic-details',
  ERROR: '*',
}

export default Routes
