// @flow

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AccountGetToken: ['id_token', 'access_token', 'expires_in'],
  AccountSetClaims: ['claims'],
  AccountLoadCollection: ['collection'],
  AccountSetSimulation: ['collection', 'simulation', 'present', 'forceReload', 'runTrigger', 'nextUrl', 'newTab'],
  AccountSetTrigger: ['data'],
  AccountSendNotification: ['payload'],
  AccountSetHorizontal: ['param', 'horiz'],
  AccountSetProfileOptions: ['params'],
  AccountSetCollection: ['collection'],
  AccountSetSimulations: ['simulations'],
  AccountSetUrlRedirect: ['urlRedirect'],
  AccountClearUrlRedirect: [null],
  AccountSetPublicSimulation: ['simulation'],
  AccountLoadPublicSimulation: ['token', 'simulation'],
  AccountSetPublicProfile: ['publicprofile'],
  AccountSuccess: ['data'],
  AccountFailure: ['error'],
  AccountLogout: [null],
  AccountSetTour: ['active'],
  AccountSetSearch: ['searchItems'],
  AccountToggleSearch: ['searchOpen'],
  AccountSetTooltip: ['data'],
  AccountSetInteractionTooltip: ['data'],
  AccountClearInteractionTooltip: [null],
  AccountToggleInteractionTooltip: ['active'],
  AccountSetActiveInteraction: ['interactionID', 'active'],
  AccountAddInteractionClicked: ['interactionID'],
  AccountRemoveInteractionClicked: [null],
  AccountClearInteractionsClicked: [null],
  AccountSetLearnModeTourVisited: [null],
  AccountAllowOutline: ['allow'],
  AccountSetInteractionDuration: ['interactionDuration'],
  AccountCrossBrowserSendEventToIframes: ['data'],
  AccountCrossBrowserRelayIframeMessage: ['data'],
  AccountClearSendEvent: [null],
  AccountClearRelayEvent: [null],
  AccountSetCoreVersion: ['coreVersion'],
  AccountSetAvailablePaths: ['availablePaths', 'interactionIndex'],
})

export const AccountTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  claims: null,
  collection: null,
  simulations: null,
  simulation: null,
  publicprofile: null,
  data: null,
  error: null,
  fetching: false,
  tourActive: false,
  searchItems: [],
  searchOpen: false,
  tooltipContainerStyles: {
    position: 'absolute',
  },
  tooltipStyles: {
    position: 'absolute',
  },
  tooltipPlacement: 'top',
  tooltipContent: '<strong>Click</strong> the blue buttons to interact with the tour.',
  tooltipAction: 'Try it now',
  interactionTooltipContainerStyles: {
    position: 'absolute',
  },
  interactionTooltipStyles: {
    position: 'absolute',
  },
  interactionTooltipPlacement: 'top',
  interactionTooltipContent: '<strong>Click</strong> the blue buttons to interact with the tour.',
  interactionTooltipContainerActive: false,
  interactionTooltipActive: false,
  interactionsClicked: [],
  interactionID: undefined,
  allowOutline: false,
  interactionDuration: 0,
  activeInteractionID: null,
  coreVersion: null,
  availablePaths: [],
  pathsInteractionIndex: -1,
})

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = state => state.merge({ error: null, fetching: true, account: null })

// we've successfully completed an API call.
export const success = (state, { data }) => state.merge({ fetching: false, error: null, data })

// we've had a problem with our API call.
export const failure = (state, { error }) =>
  state.merge({
    fetching: false,
    error: error || 'Unknown Error',
    data: null,
  })

export const reset = () => INITIAL_STATE
export const setCollection = (state, { collection }) => state.merge({ collection })
export const setSimulations = (state, { simulations }) => state.merge({ simulations })
export const setUrlRedirect = (state, { urlRedirect }) => state.merge({ urlRedirect })
export const clearUrlRedirect = state => state.merge({ urlRedirect: null })
export const setPublicSimulation = (state, { simulation }) => state.merge({ simulation })
export const setPublicProfile = (state, { publicprofile }) => state.merge({ publicprofile })
export const setToken = (state, { token }) => state.merge({ token })
export const setClaims = (state, { claims }) => state.merge({ claims })
export const doNothing = state => state
export const setTour = (state, { active }) => state.merge({ tourActive: active })
export const setSearch = (state, { searchItems }) => state.merge({ searchItems })
export const toggleSearch = (state, { searchOpen }) => state.merge({ searchOpen })

export const setTooltip = (state, { data }) => {
  let returnData = {}
  if (data?.tooltip?.left >= 0) {
    returnData = {
      tooltipContainerStyles: {
        position: 'absolute',
        top: `${data.iframe.top}%`,
        left: `${data.iframe.left}%`,
        height: `${data.iframe.bottom - data.iframe.top}%`,
        width: `${data.iframe.right - data.iframe.left}%`,
      },
      tooltipStyles: {
        position: 'absolute',
        top: `${data.tooltip.top}%`,
        left: `${data.tooltip.left}%`,
        height: `${data.tooltip.height}%`,
        width: `${data.tooltip.width}%`,
      },
      // 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left'
      // 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
      tooltipPlacement: data.tooltip.placement || 'top',
      tooltipContent: data.tooltip.content || '<strong>Click</strong> the blue buttons to interact with the tour.',
      tooltipAction: data.tooltip.action || 'Try it now',
    }
  }
  return state.merge(returnData)
}

export const setInteractionTooltip = (state, { data }) => {
  let returnData = {}
  if (data?.tooltip?.left >= 0) {
    // console.log('set', data)
    returnData = {
      interactionTooltipContainerStyles: {
        position: 'absolute',
        top: `${data.iframe.top}px`,
        left: `${data.iframe.left}px`,
        height: `${data.iframe.bottom - data.iframe.top}px`,
        width: `${data.iframe.right - data.iframe.left}px`,
      },
      interactionTooltipStyles: {
        position: 'absolute',
        backgroundColor: 'rgba(230, 61, 159, .3)',
        border: '2px solid #FF3CAE',
        top: `calc(${data.tooltip.top}% - 5px)`,
        left: `calc(${data.tooltip.left}% - 5px)`,
        height: `${data.tooltip.height + 10}px`,
        width: `${data.tooltip.width + 10}px`,
      },
      // 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left'
      // 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
      interactionTooltipPlacement: data.placement || 'top',
      interactionTooltipContent: data.content || '',
      interactionTooltipContainerActive: true,
    }
  }
  return state.merge(returnData)
}

export const clearInteractionTooltip = state => state.merge({ interactionTooltipContainerActive: false })
export const toggleInteractionTooltip = (state, { active }) => state.merge({ interactionTooltipActive: active })

export const setActiveInteraction = (state, { interactionID, active }) =>
  state.merge({ activeInteractionID: active ? interactionID : undefined })

export const addInteractionClicked = (state, { interactionID }) => {
  const interactionsClicked = state.interactionsClicked.includes(interactionID)
    ? state.interactionsClicked
    : [...state.interactionsClicked, interactionID]
  return state.merge({ interactionsClicked })
}

export const removeInteractionClicked = state => {
  const interactionsClicked = [...state.interactionsClicked]
  interactionsClicked.pop()
  return state.merge({ interactionsClicked })
}

export const clearInteractionsClicked = state => {
  // console.log('clearInteractionsClicked')
  return state.merge({ interactionsClicked: [] })
}

export const allowOutline = (state, { allow }) => state.merge({ allowOutline: allow })
export const setInteractionDuration = (state, { interactionDuration }) => state.merge({ interactionDuration })
export const setCoreVersion = (state, { coreVersion }) => state.merge({ coreVersion })

export const setAvailablePaths = (state, { availablePaths, interactionIndex }) =>
  state.merge({ availablePaths, pathsInteractionIndex: interactionIndex })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCOUNT_GET_TOKEN]: request,
  [Types.ACCOUNT_SET_CLAIMS]: setClaims,
  [Types.ACCOUNT_LOAD_COLLECTION]: request,
  [Types.ACCOUNT_LOAD_PUBLIC_SIMULATION]: request,
  [Types.ACCOUNT_SET_PUBLIC_SIMULATION]: setPublicSimulation,
  [Types.ACCOUNT_SET_PUBLIC_PROFILE]: setPublicProfile,
  [Types.ACCOUNT_SET_SIMULATION]: request,
  [Types.ACCOUNT_SET_TRIGGER]: request,
  [Types.ACCOUNT_SEND_NOTIFICATION]: request,
  [Types.ACCOUNT_SET_HORIZONTAL]: request,
  [Types.ACCOUNT_SET_PROFILE_OPTIONS]: request,
  [Types.ACCOUNT_SET_COLLECTION]: setCollection,
  [Types.ACCOUNT_SET_SIMULATIONS]: setSimulations,
  [Types.ACCOUNT_SET_URL_REDIRECT]: setUrlRedirect,
  [Types.ACCOUNT_CLEAR_URL_REDIRECT]: clearUrlRedirect,
  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
  [Types.ACCOUNT_LOGOUT]: reset,
  [Types.ACCOUNT_SET_TOUR]: setTour,
  [Types.ACCOUNT_SET_SEARCH]: setSearch,
  [Types.ACCOUNT_TOGGLE_SEARCH]: toggleSearch,
  [Types.ACCOUNT_SET_TOOLTIP]: setTooltip,
  [Types.ACCOUNT_SET_INTERACTION_TOOLTIP]: setInteractionTooltip,
  [Types.ACCOUNT_CLEAR_INTERACTION_TOOLTIP]: clearInteractionTooltip,
  [Types.ACCOUNT_TOGGLE_INTERACTION_TOOLTIP]: toggleInteractionTooltip,
  [Types.ACCOUNT_SET_ACTIVE_INTERACTION]: setActiveInteraction,
  [Types.ACCOUNT_ADD_INTERACTION_CLICKED]: addInteractionClicked,
  [Types.ACCOUNT_REMOVE_INTERACTION_CLICKED]: removeInteractionClicked,
  [Types.ACCOUNT_CLEAR_INTERACTIONS_CLICKED]: clearInteractionsClicked,
  [Types.ACCOUNT_SET_LEARN_MODE_TOUR_VISITED]: request,
  [Types.ACCOUNT_ALLOW_OUTLINE]: allowOutline,
  [Types.ACCOUNT_SET_INTERACTION_DURATION]: setInteractionDuration,
  [Types.ACCOUNT_SET_CORE_VERSION]: setCoreVersion,
  [Types.ACCOUNT_SET_AVAILABLE_PATHS]: setAvailablePaths,
})

/* ------------- Selectors ------------- */

// Is the current account logged in?
// export const isLoggedIn = (loginState) => loginState.account !== null;
// export const isLoggedIn = (account) => account !== null;
